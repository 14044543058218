import React from 'react';
import { graphql, PageProps } from 'gatsby';
import PageHeader from '../../components/PageHeader/PageHeader';
import GuideBlocks from '../../components/GuideBlocks/GuideBlocks';
import { getArticles, getFeaturedImageDataFromWpPost } from '../../utils';
import PaginationV2 from '../../components/PaginationV2/PaginationV2';
import Seo from '../../components/Seo';
import { HT_PAGE_CATEGORY_ARCHIVE_PAGE, MAX_NUMBER_OF_POSTS_PER_PAGE } from '../../constants';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface ICategoryArchiveTemplateProps {
  posts: Queries.WpPostConnection;
  category: Queries.WpCategory;
}

const CategoryArchiveTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<ICategoryArchiveTemplateProps>) => {
  const {
    posts: { nodes },
  } = data;
  const basePath = (pageContext as any)?.uri || '/news';
  const title = (pageContext as any)?.name;
  const currentPageIndex = (pageContext as any)?.pageNumber - 1 || 0;
  let posts: Queries.WpPost[] = [];

  if (nodes && Array.isArray(nodes)) {
    posts = nodes;
  }

  const articles = getArticles(posts);

  const totalNumberOfPages = (pageContext as any)?.totalNumberOfPages || 0;
  return (
    <HTPageWrapper category={title + ', ' + HT_PAGE_CATEGORY_ARCHIVE_PAGE} title={title} location={location}>
      <PageHeader
        showGraphic
        title={title}
        breadCrumbs={[
          {
            text: 'Home',
            link: '/',
          },
        ]}
      />
      <GuideBlocks articles={articles} variant="columns" />
      {totalNumberOfPages > 0 && (
        <PaginationV2
          currentPageIndex={currentPageIndex}
          pageSize={MAX_NUMBER_OF_POSTS_PER_PAGE}
          numberOfPages={totalNumberOfPages}
          basePath={basePath}
        />
      )}
    </HTPageWrapper>
  );
};

export default CategoryArchiveTemplate;

export const Head = ({ pageContext, data }: PageProps<ICategoryArchiveTemplateProps>) => {
  const wpSeo = data?.category?.seo;
  const firstPost = data?.posts?.nodes[0];
  const pageNumber = (pageContext as any)?.pageNumber;
  const uriPrefix = (pageContext as any)?.uri;
  const pageSuffix = pageNumber ? `page/${pageNumber}/` : '';
  const seo = { ...wpSeo };
  seo.canonical = uriPrefix + pageSuffix;
  seo.opengraphUrl += pageSuffix;
  const yoastSchemaGraph = seo?.schema?.raw && JSON.parse(seo.schema.raw);
  const breadcrumbId = uriPrefix + pageSuffix + '#breadcrumb';
  if (yoastSchemaGraph) {
    yoastSchemaGraph['@graph'].forEach((element: any) => {
      if (element['@type'] === 'CollectionPage') {
        const primaryImageId = uriPrefix + pageSuffix + '#primaryimage';
        element['url'] += pageSuffix;
        element['name'] = wpSeo?.title + ' - Page ' + pageNumber;
        element['primaryImageOfPage'] = { '@id': primaryImageId };
        element['image'] = { '@id': primaryImageId };
        if (element['breadcrumb']) {
          element['breadcrumb']['@id'] = breadcrumbId;
        }
      }
      if (element['@type'] === 'BreadcrumbList') {
        element['@id'] = breadcrumbId;
      }
    });
    const newYoastSchemaGraph = JSON.stringify(yoastSchemaGraph);
    seo.schema.raw = newYoastSchemaGraph;
  }
  const featuredImageData = firstPost ? getFeaturedImageDataFromWpPost(firstPost) : null;
  const categoryName = (pageContext as any)?.name || '';

  return (
    <Seo
      wpSeo={seo}
      pageNumber={pageNumber}
      featuredImageData={featuredImageData}
      isNewsListingPage={uriPrefix === '/news/'}
      isArchivePage={true}
      archiveName={categoryName}
      uri={uriPrefix}
    />
  );
};

export const query = graphql`
  query ArchivePosts($id: String, $start: Int, $postToExcludeId: Int) {
    category: wpCategory(id: { eq: $id }) {
      seo {
        title
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        metaDesc
        opengraphDescription
        opengraphImage {
          height
          sourceUrl
          width
          mediaType
        }
        opengraphType
        opengraphTitle
        opengraphUrl
        opengraphSiteName
        opengraphPublisher
        opengraphModifiedTime
        schema {
          raw
        }
      }
    }
    posts: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { id: { eq: $id } } } }
        databaseId: { nin: [$postToExcludeId] }
      }
      limit: 21
      skip: $start
      sort: { date: DESC }
    ) {
      nodes {
        title
        uri
        dateGmt
        excerpt
        author {
          node {
            uri
            name
          }
        }
        featuredImage {
          node {
            caption
            width
            height
            sourceUrl
          }
        }
      }
    }
  }
`;
